<!--
 * @Description: 巡查管理--巡查执行情况--列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors  : 小广
 * @LastEditTime : 2019-12-19 13:58:04
 -->
<template>
  <div class="inspectManagerTaskList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="delay"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
     <template #headerSlot>
        <v-button text="全部作废" permission="invalidAll" @click="allDeleteHandle"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="巡查地点" v-model="searchParams.inspectSite"></v-input>
        <v-select
          label="巡查类型"
          v-model="searchParams.inspectType"
          :options="typeOps"
        ></v-select>
        <v-select
          label="巡查状态"
          v-model="searchParams.inspectStatus"
          :options="inspectStatusOps"
        ></v-select>
        <v-select
          label="巡查反馈"
          v-model="searchParams.result"
          :options="inspectResultOps"
        ></v-select>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
        <v-datepicker
          label="计划完成时间段"
          :startTime.sync="searchParams.planStartTime"
          :endTime.sync="searchParams.planEndTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="查看"
          type="text"
          permission="view"
          @click="look(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row.inspectStatus === 1"
          text="作废"
          type="text"
          permission="delete"
          @click="cancellationHandle(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row.inspectStatus != 1"
          text="删除"
          type="text"
          permission="delete2"
          @click="deleteHandle(scope.row)"
        ></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量作废"
          permission="delete"
          @click="batchOnOffHandle(scope.selectedData)"
        ></v-button>
        <v-button
          text="批量删除"
          permission="delete2"
          @click="batchDeleteHandle(scope.selectedData)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getTaskListURL,
  exportTaskListURL,
  handleInspectTaskCancelURL,
  allDeleteTaskCancelURL,
  batchDeleteTaskUrl
} from './api'
import { typeOps, typeMap, inspectStatusOps, inspectStatusMap, inspectResultOps, inspectResultMap } from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: '',
  data () {
    return {
      searchUrl: getTaskListURL,
      typeOps: typeOps(1),
      exportUrl: exportTaskListURL,
      inspectStatusOps: inspectStatusOps,
      inspectResultOps: inspectResultOps,
      communityParams,
      tenantParams,
      searchParams: {
        inspectSite: '',
        inspectType: undefined,
        inspectStatus: undefined,
        result: undefined,
        communityId: '',
        regionId: '',
        planStartTime: '',
        planEndTime: ''
      },
      headers: [
        {
          prop: 'inspectSite',
          label: '巡查地点'
        },
        {
          prop: 'inspectTypeStr',
          label: '巡查类型',
          formatter (row) {
            let typeStr = typeMap[row.inspectType]
            return typeStr
          }
        },
        {
          prop: 'inspectUsername',
          label: '巡查人'
        },
        {
          prop: 'planEndTime',
          label: '计划完成时间'
        },
        {
          prop: 'uploadTime',
          label: '上传时间'
        },
        {
          prop: 'realEndTime',
          label: '实际完成时间'
        },
        {
          prop: 'inspectStatusStr',
          label: '巡查状态',
          formatter (row) {
            let statusStr = inspectStatusMap[row.inspectStatus]
            return statusStr
          }
        },
        {
          prop: 'resultStr',
          label: '巡查反馈',
          formatter (row) {
            let resultStr = inspectResultMap[row.result]
            return resultStr
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        }
      ]
    }
  },

  methods: {

    // 是否可选
    selectable (row) {
      return true
      // return row.inspectStatus === 1
    },

    look (row) {
      this.$router.push({
        name: 'inspectTaskForm',
        query: {
          id: row.id
        }
      })
    },

    async allDeleteHandle () {
      let msg = '确认后将作废当前搜索结果中的全部待巡查任务，请谨慎操作'
      let isOk = await this.$confirm(msg, { title: '提示' })
      isOk && this.requestForAllDelete()
    },

    async batchOnOffHandle (selectedData) {
      let list = selectedData.data || []
      let deleteList = list.filter((item)=>{ return item.inspectStatus==1})
      if(deleteList.length!=list.length) {
        this.$message({
          type: 'error',
          message: '仅支持作废巡查状态为“待巡查”的巡查执行情况!',
          center: true
        })
        return
      }
      if (list.length) {
        let idList = list.map(item => item.id) || []
        let params = {
          ids: idList.join(',')
        }
        let isOk = await this.$confirm('作废后不可恢复，确定要作废吗？', { title: '提示' })
        isOk && this.requestForUpdateStatus(params)
      } else {
        this.$message('请先选择要操作的数据')
      }
    },

    // 作废操作
    async cancellationHandle (row) {
      let params = {
        ids: [row.id].join(',')
      }
      let isOk = await this.$confirm('作废后不可恢复，确定要作废吗？', { title: '提示' })
      isOk && this.requestForUpdateStatus(params)
    },

    // 全部作废
    requestForAllDelete () {
      let postParams = {}
      Object.keys(this.searchParams).forEach(key => {
        if (this.searchParams[key] !== undefined) {
          postParams[key] = this.searchParams[key]
        }
      })
      let _this = this
      let requestUrl = allDeleteTaskCancelURL
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      this.$axios({
        method: 'POST',
        url: requestUrl,
        headers,
        data: this.$qs.stringify(postParams)
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    // 批量作废
    requestForUpdateStatus (params) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: handleInspectTaskCancelURL,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        data: this.$qs.stringify(params)
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    },
    // 删除的操作
    deleteHandle(row) {
      let _this = this;
      this.$confirm(
        "请确认是否执行此操作",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          _this.requestForDelete(row);
        }
      });
    },
    // 删除的请求
    requestForDelete(row) {
      let _this = this;
      this.$axios({
        method: "DELETE",
        url: batchDeleteTaskUrl,
        data: [row.id],
      }).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },
    batchDeleteHandle(SelectData) {
      console.log(SelectData, "++++");
      if(SelectData.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
        return
      }
      let deleteList = SelectData.data.filter((item)=>{ return item.inspectStatus==1})
      if(deleteList.length) {
        this.$message({
          type: 'error',
          message: '巡查状态为“待巡查”的巡查执行情况不支持删除!',
          center: true
        })
        return
      }
      let _this = this;
      let list = [];
      this.$confirm(
        "请确认是否执行此操作",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          SelectData.data.forEach((item) => {
            list.push(item.id);
          });
          _this.batchDeleteFun(list);
        }
      });
    },

    batchDeleteFun(list) {
      let _this = this;
      this.$axios({
        method: "DELETE",
        url: batchDeleteTaskUrl,
        data: list,
      }).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },
  }
}
</script>
